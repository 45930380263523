import React, { useEffect, useState } from 'react';
import httpApi from 'httpApi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IBusiness } from 'utils/interfaces/Business';

export const BusinessList = () => {
    const navigate = useNavigate();
    const { t } = useTranslation()
    const [businesses, setBusinesses] = useState<IBusiness[]>([]);
    const [loading, setLoading] = useState(false);
    const handleScheduleAppointment = (businessId: number) => {
        navigate(`/schedule-appointment/${businessId}`);
    };

    useEffect(() => {
        const fetchBusinesses = async () => {
            try {
                setLoading(true);
                const { data: { data } } = await httpApi.get('/api/businesses');
                console.log('response.data', data);
                setBusinesses(data);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setLoading(false);
            }
        };
        fetchBusinesses();
    }, []);
    if (loading) {
        return <div>
            <p>{t('loading')}</p>
        </div>
    }
    return (
        <div className="business-list">
            <h2>{t('businesses_available')}</h2>
            {businesses.length ? <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {businesses.map((business) => (
                    <li key={business.id} className="bg-white dark:bg-gray-600 rounded-lg shadow-md p-4">
                        <div>
                            <h3 className="text-xl font-semibold">{business.attributes.name}</h3>
                            <p className="dark:text-white text-gray-600">{business.attributes.description}</p>
                            <button
                                onClick={() => handleScheduleAppointment(business.id)}
                                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none"
                            >
                                {t('schedule_appointment.title')}
                            </button>
                        </div>
                    </li>
                ))}
            </ul> :
                <div className="text-center">
                    <p>{t('no_businesses_available')}</p>
                </div>
            }
        </div>
    );
};


