import { AppointmentItem } from './AppointmentItem';
import type { AppointmentListByMonthProps } from '../../utils/interfaces/Appointment';

export const AppointmentListByMonth = ({ appointmentsByMonth, toggleDropdown, dropdownOpen }: AppointmentListByMonthProps) => {
  return (
    <>
      { Object.keys(appointmentsByMonth).map((month) => (
        <div key={ month } className="space-y-2">
          <h3 className="text-lg font-bold my-2 capitalize">{ month }</h3>
          { appointmentsByMonth[month].map((appointment) => (
            <AppointmentItem
              key={ appointment.id }
              appointment={ appointment }
              toggleDropdown={ toggleDropdown }
              dropdownOpen={ dropdownOpen }
            />
          )) }
        </div>
      )) }
    </>
  );
};