import React from 'react';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const currentYear = new Date().getFullYear();
    const { t } = useTranslation();

    return (
        <div className="bg-gray-800 text-white p-4 text-center dark:bg-gray-900">
            {t('app_name')} - {currentYear} &copy;
        </div>
    );
};
