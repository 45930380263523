import React from 'react'
import { AuthenticatedLayout } from 'Layouts/AuthenticatedLayout'
import ScheduleAppointmentForm from 'components/ScheduleAppointmentForm'

export const ScheduleAppointment = () => {
    return (
        <AuthenticatedLayout>
            <ScheduleAppointmentForm />
        </AuthenticatedLayout>
    )
}