import { RootState } from 'app_redux/store';
import { AuthenticatedLayout } from 'Layouts/AuthenticatedLayout';
import { PublicLayout } from 'Layouts/PublicLayout';
import React from 'react';
import { useSelector } from 'react-redux';

export const NotFoundPage = () => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const LayoutToRender = isAuthenticated ? AuthenticatedLayout : PublicLayout;

    return (
        <LayoutToRender>
            <div className="min-h-screen bg-gray-100 dark:bg-gray-800 flex flex-col justify-center sm:py-12">
                <div className="text-center">
                    <h1 className="text-9xl font-bold text-gray-900 dark:text-gray-100">404</h1>
                    <p className="text-2xl font-medium text-gray-700 dark:text-gray-300 mt-2">Page Not Found</p>
                    <p className="text-gray-600 dark:text-gray-400 mt-4">

                    </p>
                    <a
                        href="/"
                        className="mt-8 inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                        Go to Homepage
                    </a>
                </div>
            </div>
        </LayoutToRender>
    );
};
