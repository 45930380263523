export type IPageRoute = {
    name: string;
    slug: string;
    path: string;
    translation_tag: string;
}
export const PublicRoutes: IPageRoute[] = [
    {
        name: 'Login',
        slug: 'login',
        translation_tag: 'login',
        path: '/login'
    },
    {
        name: 'Signup',
        slug: 'signup',
        translation_tag: 'signup',
        path: '/signup'
    }
]

export const AuthUserRoutes: IPageRoute[] = [
    {
        name: 'Home',
        slug: 'home',
        translation_tag: 'route_home',
        path: '/'
    },
    {
        name: 'Appointment',
        slug: 'appointment',
        translation_tag: 'route_appointments',
        path: '/appointment'
    }
]