import { useState, useEffect } from 'react';
import httpApi from '../httpApi';
import type { IAppointment } from '../utils/interfaces/Appointment';

export const useAppointments = () => {
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        setLoading(true);
        const { data: { customData } } = await httpApi.get('/api/custom-appointments');
        console.log(customData);
        setAppointments(customData || []);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };
    fetchAppointments();
  }, []);

  return { appointments, loading };
};