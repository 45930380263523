import React, {
    useCallback,
    useEffect,
    // useMemo,
    useState
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// import { Calendar, SlotInfo, Views } from 'react-big-calendar';
// import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './ScheduleCalendar.css';
// import { getCalendarLocalizer } from 'i18n';
import {
    format,
    // Locale 
} from 'date-fns';
// import { es, enUS } from 'date-fns/locale';
import httpApi from 'httpApi';
import { useParams } from 'react-router-dom';
import { IBusiness } from 'utils/interfaces/Business';
import { IService } from 'utils/interfaces/BusinessService';
import { useSelector } from 'react-redux';
import { RootState } from 'app_redux/store';
import useFormatToMXN from 'utils/useFormatToMXN';

// interface Event {
//     title: string;
//     start: Date;
//     end: Date;
//     allDay?: boolean;
// }

export const ScheduleAppointmentForm = () => {
    const user = useSelector((state: RootState) => state.auth.user)
    // const [dateSelected, setDateSelected] = useState<Date | null>(new Date());
    const [businessData, setBusinessData] = useState<IBusiness | null>(null);
    const [businessServices, setBusinessServices] = useState<IService[] | []>([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const routeParams = useParams();
    const { t } = useTranslation();
    const formatToMXN = useFormatToMXN();

    // const calendarFormats = useMemo(() => {
    //     const locale = t('lang_code_selected').toLowerCase();

    //     const locales: { en: Locale; es: Locale } = {
    //         en: enUS,
    //         es: es,
    //     };

    //     return {
    //         monthHeaderFormat: (date: Date) => format(date, 'MMMM yyyy', { locale: locales[locale as keyof typeof locales] }),
    //         dayFormat: (date: Date) => format(date, 'EEEE', { locale: locales[locale as keyof typeof locales] }),
    //         dateFormat: (date: Date) => format(date, 'dd', { locale: locales[locale as keyof typeof locales] }),
    //     };
    // }, [t]);

    // const [events, setAvailableSlots] = useState<Event[]>([]);
    const { register, handleSubmit, formState: { errors } } = useForm();
    // const localizer = getCalendarLocalizer();
    const fetchBusinessData = useCallback(async () => {
        try {
            const { data: { data: business } } = await httpApi.get(`/api/businesses/${routeParams.businessId}`);
            return business;
        } catch (error) {
            console.error('Error fetching business data:', error);
            return null;
        }

    }, [routeParams.businessId])
    const fetchMonthAvailability = useCallback(async (date: Date) => {
        try {
            const businessId = routeParams.businessId;
            if (!businessId) return;
            const formattedDate = format(date, 'yyyy-MM-dd');
            const { data: { availability } } = await httpApi.get(`/api/business-availability/${businessId}?date=${formattedDate}`);
            return availability;
        } catch (error) {
            console.error('Error fetching availability:', error);
            throw error;
        }
    }, [routeParams.businessId]);
    const fetchBusinessServices = useCallback(async () => {
        try {
            const { data: { data: businessServices } } = await httpApi.get(`/api/services?filters[business][id]=${routeParams.businessId}`);
            return businessServices;
        } catch (error) {
            console.error('Error fetching business services:', error);
            return null;
        }

    }, [routeParams.businessId])
    useEffect(() => {
        if (!routeParams.businessId) return;
        async function fetchData() {
            setLoading(true);
            try {
                const [businessData, availabilitySlots, servicesResponse] = await Promise.all([
                    fetchBusinessData(),
                    fetchMonthAvailability(new Date()),
                    fetchBusinessServices(),
                ]);
                console.log('Business data fetched:', businessData);
                console.log('Availability slots fetched:', availabilitySlots);
                console.log('Business services fetched:', servicesResponse);
                setBusinessData(businessData);
                // setAvailableSlots(availabilitySlots)
                setBusinessServices(servicesResponse);
            } catch (error) {
                console.error('Error fetching business data:', error);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [routeParams.businessId, fetchBusinessData, fetchMonthAvailability, fetchBusinessServices]);

    // const handleSelectSlot = (slotInfo: SlotInfo) => {
    //     setDateSelected(slotInfo.start);
    // };

    const onSubmit = async (data: any) => {
        try {
            if (!user?.id || !routeParams.businessId) {
                return alert("Please enter your information");
            }
            const { date, time } = data;
            const dateTime = new Date(`${date}T${time}:00`);
            console.log(`Selected date and time: ${dateTime}`);
            const formData = {
                service: Number(data.serviceId),
                date: dateTime,
                business: Number(routeParams.businessId),
                user: String(user.id)
            }
            const savedAppointment = await httpApi.post('/api/appointments', { data: formData });
            setSuccess(true);
            setError(false);
            console.log('Saved Appointments:', savedAppointment.data);
        } catch (error) {
            setSuccess(false);
            setError(true);
            console.error('Error saving appointment:', error);
        }
    };

    // const minDate = new Date();
    // const maxDate = moment().add(12, 'months').toDate();

    // const messages = useMemo(() => ({
    //     today: t('calendar.today'),
    //     month: t('calendar.month'),
    //     week: t('calendar.week'),
    //     day: t('calendar.day'),
    //     agenda: t('calendar.agenda'),
    //     allDay: t('calendar.allDay'),
    //     event: t('calendar.event'),
    //     date: t('calendar.date'),
    //     time: t('calendar.time'),
    //     previous: t('calendar.back'),
    //     next: t('calendar.next'),
    //     noEventsInRange: t('calendar.noEvents'),

    // }), [t]);
    // const dayPropGetter = (date: Date) => {
    //     const isSelected = dateSelected && date.getTime() === dateSelected.getTime();
    //     return {
    //         className: isSelected ? 'selected-day bg-blue-500 text-white dark:bg-blue-400 dark:text-black' : '',
    //     };
    // };
    const renderAlert = () => {
        if (error) {
            return (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold mr-2">{t('error_alert_title')}</strong>
                    <span className="block sm:inline">{t('schedule_appointment.appointment_created_error')}</span>
                </div>
            );
        }
        if (success)
            return (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold mr-2">{t('success_alert_title')}</strong>
                    <span className="block sm:inline">{t('schedule_appointment.appointment_created_success')}</span>
                </div>
            );
    }
    return (
        <div className="min-h-screen bg-gray-100 dark:bg-gray-800 flex flex-col md:flex-row justify-center sm:py-12">
            {/* Form Section */}
            <div className="w-full md:w-1/2 p-4">
                <h1 className="font-bold text-center text-2xl mb-5">
                    {t('schedule_appointment.title')}
                </h1>
                <div className="bg-white dark:bg-gray-700 shadow w-full rounded-lg divide-y divide-gray-200 dark:divide-gray-600">
                    <div className="px-5 py-7">
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                            {renderAlert()}
                            <div className='flex gap-x-4'>
                                <div className="flex-col">
                                    {businessData?.attributes?.profile_picture?.formats?.thumbnail?.url ? <img className="rounded min-w-24 object-cover min-h-24 max-w-36 max-h-36 bg-gray-900" src={businessData?.attributes?.profile_picture?.formats?.thumbnail?.url || ''} alt="Extra large avatar" />
                                        :
                                        <img className="rounded min-w-24 object-cover min-h-24 max-w-36 max-h-36 bg-gray-900" src="https://via.placeholder.com/250" alt="Extra large avatar" />
                                    }
                                </div>
                                <div className="flex-col">
                                    <h3 className="font-semibold text-4xl text-gray-800 dark:text-gray-300 pb-1">
                                        {businessData?.attributes?.name}
                                    </h3>
                                    <p className="text-md text-gray-600 dark:text-gray-300">
                                        {businessData?.attributes?.description || ''}
                                    </p>
                                </div>
                            </div>
                            <div>
                                <label className="font-semibold text-sm text-gray-600 dark:text-gray-300 pb-1 block">
                                    {t('schedule_appointment.services')}
                                </label>
                                <select
                                    disabled={loading}
                                    {...register('serviceId', { required: true })}
                                    className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 dark:bg-gray-600 dark:text-gray-100 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                    <option value="">{t('schedule_appointment.select_service')}</option>
                                    {businessServices.map(service => (
                                        <option key={service.id} value={service.id}>
                                            {service.attributes.name} {service.attributes.price ? `(${formatToMXN(service.attributes.price)})` : ''}
                                        </option>
                                    ))}
                                </select>
                                {errors.serviceId && <p className="text-red-500 text-xs mt-1">{t('schedule_appointment.service')} {t('is_required')}</p>}
                            </div>
                            <div>
                                <label className="font-semibold text-sm text-gray-600 dark:text-gray-300 pb-1 block">
                                    {t('schedule_appointment.date')}
                                </label>
                                <input
                                    disabled={loading}
                                    type="date"
                                    {...register('date', { required: true })}
                                    className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 dark:bg-gray-600 dark:text-gray-100 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.date && <p className="text-red-500 text-xs mt-1">{t('schedule_appointment.date')} {t('is_required')}</p>}
                            </div>

                            <div>
                                <label className="font-semibold text-sm text-gray-600 dark:text-gray-300 pb-1 block">
                                    {t('schedule_appointment.time')}
                                </label>
                                <input
                                    disabled={loading}
                                    type="time"
                                    {...register('time', { required: true })}
                                    className="block w-full rounded-md border-0 py-1.5 pl-2 pr-2 text-gray-900 dark:bg-gray-600 dark:text-gray-100 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.time && <p className="text-red-500 text-xs mt-1">{t('schedule_appointment.time')} {t('is_required')}</p>}
                            </div>

                            <button
                                disabled={loading}
                                type="submit"
                                className="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
                            >
                                <span className="inline-block mr-2">{loading ? t('loading') : t('schedule_appointment.submit')}</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            {/* Calendar Section */}
            {/* <div className="w-full md:w-1/2 p-4">
                <Calendar
                    defaultDate={new Date()}
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    selectable
                    views={[Views.MONTH]} // Only show month view
                    defaultView={Views.MONTH} // Set the default view to month
                    onSelectSlot={handleSelectSlot}
                    min={minDate} // Minimum date selectable
                    max={maxDate} // Maximum date selectable
                    drilldownView={null} // Disable drill down to days
                    formats={calendarFormats}
                    onNavigate={(date, view) => {
                        if (view === Views.MONTH && (date < minDate || date > maxDate)) {
                            return false; // Prevent navigating to past months or more than 12 months into the future
                        }
                    }}
                    messages={messages}
                    className={"dark-calendar"}
                    dayPropGetter={dayPropGetter}
                />
            </div> */}
        </div>
    );
};
