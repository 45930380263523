interface BadgeProps {
  text: string;
  className?: string;
}

const colors: Record<'pending' | 'confirmed' | 'canceled', string> = {
  pending: 'border-yellow-500 text-yellow-500',
  confirmed: 'border-green-500 text-green-500',
  canceled: 'border-red-500 text-red-500',
};

export const Badge = ({ text, className = '' }: BadgeProps) => {
  const chooseColor = colors[text as keyof typeof colors] || `border-${text}-500 text-${text}-500`;

  return (
    <span
      className={ `px-2 py-1 rounded-lg font-semibold inline-block text-xs border uppercase ${chooseColor} ${className}` }
    >
      { text }
    </span>
  );
};