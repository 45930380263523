import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import httpApi from 'httpApi';

type IUser = {
    id: number;
    username: string;
    email: string;
    provider: string;
    confirmed: boolean;
    blocked: boolean;
    createdAt: string;
    updatedAt: string;
    // extras
    name?: string;
    lastName?: string;
};
interface AuthState {
    isAuthenticated: boolean;
    loading: boolean;
    error: string | null;
    user: IUser | null;
}

const initialState: AuthState = {
    isAuthenticated: false,
    loading: false,
    error: null,
    user: null,
};

export const login = createAsyncThunk(
    'auth/login',
    async (credentials: { identifier: string; password: string }, thunkAPI) => {
        try {
            const response = await httpApi.post('/api/auth/local', {
                identifier: credentials.identifier,
                password: credentials.password,
            });
            localStorage.setItem('token', response.data.jwt);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue((error as any).response.data.message[0].messages[0].message);
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = null;
            localStorage.removeItem('token');
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isAuthenticated = true;
                state.loading = false;
                state.error = null;
                state.user = action.payload.user;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
