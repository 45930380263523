import { MdAccessTimeFilled, MdPlace } from "react-icons/md";
import { RiCalendarScheduleFill } from "react-icons/ri";

import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from "react-icons/md";

import { format } from 'date-fns';
import type { AppointmentItemProps } from "../../utils/interfaces/Appointment";
import { Badge } from "components/Badge/Badge";

import { useTranslation } from "react-i18next";

export const AppointmentItem = ({ appointment, toggleDropdown, dropdownOpen }: AppointmentItemProps) => {
  const date = new Date(appointment.attributes.appointmentDate);
  const { t } = useTranslation();

  const formatDate = (date: string, formatString: string) => {
    return format(new Date(date), formatString);
  };

  return (
    <div className="border p-4 rounded-lg shadow-sm bg-white dark:bg-[#0F1827] dark:border-[#0F1827] flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <div className="flex items-center justify-center border-r-2 min-w-[109px]">
          <div className="p-4 rounded-lg text-center">
            <div className="text-sm capitalize">{ format(date, 'EEEE') }</div>
            <div className="text-3xl font-bold">{ date.getDate() }</div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-5 sm:items-center">
          <div className="space-y-1">
            
            <div className="flex items-center space-x-2">
              <MdAccessTimeFilled className="text-gray-500 dark:text-gray-200 text-sm" />
              <span className="text-sm text-gray-500 dark:text-gray-200">
                { formatDate(appointment.attributes.appointmentDate, 'h:mm a') }
              </span>
            </div>

            <div className="flex items-center space-x-2">
              <MdPlace className="text-gray-500 text-md dark:text-gray-200" />
              <span className="text-sm text-gray-500 dark:text-gray-200">Online</span>
            </div>

            <div>
              <Badge text={ appointment.attributes.status } />
            </div>
          </div>

          <div className="flex flex-col">
            <span className="text-lg font-bold">{ appointment.attributes.businessName || "No Business" }</span>
            <span className="text-sm">{ appointment.attributes.serviceName || "No Service" }</span>
          </div>

        </div>
      </div>

      <div className="relative">
        <button
          onClick={ () => toggleDropdown(appointment.id) }
          className="inline-flex bg-blue-500 hover:bg-blue-600 justify-center items-center gap-2 px-4 py-2 text-sm font-medium text-white rounded-md"
        >
          {t("appointment_edit.edit")}
          { dropdownOpen === appointment.id ? (
            <MdOutlineKeyboardArrowUp />
          ) : (
            <MdOutlineKeyboardArrowDown />
          ) }
        </button>
        <div
          style={ { display: dropdownOpen === appointment.id ? 'block' : 'none' } }
          className="absolute right-0 mt-2 w-56 bg-white dark:bg-[#1F2937] rounded-md shadow-lg z-10"
        >
          <div className="py-1 px-1">
            <button
              onClick={ () => console.log('Reschedule clicked') }
              className="flex gap-2 items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-[#2D3748] dark:text-gray-200"
            >
              <RiCalendarScheduleFill />
              {t("appointment_edit.reschedule")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};