import { createSlice } from '@reduxjs/toolkit';

const themeSlice = createSlice({
    name: 'theme',
    initialState: {
        darkMode: localStorage.getItem('theme') === 'dark',
    },
    reducers: {
        setTheme: (state, action) => {
            state.darkMode = action.payload === 'dark';
            localStorage.setItem('theme', action.payload);
        },
    },
});

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
