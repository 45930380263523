import React, { ReactNode, useEffect } from 'react';
import TopBar from 'components/TopBar';
import Footer from 'components/Footer';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'app_redux/store';
import { Navigate } from 'react-router-dom';
import { setTheme } from 'app_redux/slices/themeSlice';

interface PublicLayoutProps {
    children: ReactNode;
}

export const PublicLayout: React.FC<PublicLayoutProps> = ({ children }) => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const dispatch = useAppDispatch()
    useEffect(() => {
        const savedTheme = localStorage.getItem('theme') || 'light';
        if (savedTheme === 'dark') {
            document.documentElement.classList.add('dark');
            dispatch(setTheme('dark'));
        } else {
            document.documentElement.classList.remove('dark');
            dispatch(setTheme('light'));
        }
    }, [dispatch]);
    if (isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    return (
        <div className="min-h-screen flex flex-col bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100">
            <TopBar />
            <main className="flex-grow">
                {children}
            </main>
            <Footer />
        </div>
    );
};
