import { useState } from 'react';
import { useAppointments } from '../../hooks/useAppointment';
import { AppointmentListByMonth } from './AppointmentListByMonth';
import { useTranslation } from 'react-i18next';
import { useTranslationUtils } from '../../utils/translationUtils';

export const AppointmentList = () => {
  const { t } = useTranslation();
  const { appointments, loading } = useAppointments();
  const { getMonthTranslation } = useTranslationUtils();

  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);

  const toggleDropdown = (id: number) => {
    setDropdownOpen(dropdownOpen === id ? null : id);
  };

  const appointmentsByMonth = appointments.length > 0 ? appointments.reduce((acc, appointment) => {
    const date = new Date(appointment.attributes.appointmentDate);
    const month = `${getMonthTranslation(date)} ${date.getFullYear()}`;
    if (!acc[month]) {
      acc[month] = [];
    }
    acc[month].push(appointment);
    return acc;
  }, {} as Record<string, typeof appointments>) : {};

  if (loading) {
    return <div>{ t('loading') }</div>;
  }

  if (appointments.length === 0) {
    return <div>{ t('appointments_unavailable') }</div>;
  }

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">{ t('appointments_available') }</h2>
      <AppointmentListByMonth
        appointmentsByMonth={ appointmentsByMonth }
        toggleDropdown={ toggleDropdown }
        dropdownOpen={ dropdownOpen }
      />
    </div>
  );
};