import { logout } from 'app_redux/slices/authSlice';
import { RootState, useAppDispatch } from 'app_redux/store';
import { LanguageDropdown } from 'components/LanguageDropdown';
import { ThemeToggle } from 'components/ThemeToggle';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FaUserCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const AvatarDropdown = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useAppDispatch()
    const handleLogout = () => {
        dispatch(logout())
    }
    return (
        <div className="relative">
            <button onClick={() => setDropdownOpen(!dropdownOpen)} className="flex items-center space-x-2 focus:outline-none">
                <FaUserCircle className="w-8 h-8 text-gray-300" />
                <span className="text-gray-600 dark:text-gray-300">{user?.name}</span>
            </button>
            <div style={{
                display: dropdownOpen ? 'block' : 'none'
            }} className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-700 rounded-md shadow-lg z-20 min-w-64">
                <div className="py-2 px-4">
                    <p className="text-gray-600 dark:text-gray-300">Logged in as</p>
                    <p className="font-semibold text-gray-600 dark:text-gray-300">{user?.email}</p>
                    <p className="text-gray-600 dark:text-gray-300">{user?.email}</p>
                </div>
                <div className="border-t border-gray-200 dark:border-gray-600"></div>
                <span className="w-full text-left py-2 px-4 text-gray-600 dark:text-gray-300 flex flex-row content-between">
                    <span className='flex-1'>
                        {t('toggle_theme')}
                    </span>
                    <ThemeToggle />
                </span>
                <div className="border-t border-gray-200 dark:border-gray-600"></div>
                <span className="w-full text-left py-2 px-4 text-gray-600 dark:text-gray-300 flex flex-row content-between">
                    <span className='flex-1'>
                        {t('current_language')}
                    </span>
                    <LanguageDropdown />
                </span>
                <div className="border-t border-gray-200 dark:border-gray-600"></div>
                <button
                    onClick={handleLogout}
                    className="w-full text-left py-2 px-4 text-red-600 dark:text-red-300 hover:bg-gray-100 dark:hover:bg-gray-800 font-bold"
                >
                    Logout
                </button>
            </div>
        </div>
    )
}

export default AvatarDropdown