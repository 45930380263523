import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import USFlag from 'assets/img/us-flag.png'
import MXFlag from 'assets/img/mx-flag.png'
export const LanguageDropdown: React.FC = () => {
    const { i18n, t } = useTranslation();
    useEffect(() => {
        const language = localStorage.getItem('language');
        if (language) {
            i18n.changeLanguage(language);
        }
    }, [i18n]);
    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
        localStorage.setItem('language', language);
    };
    const showFlagFromCurrentLanguage = (language: string) => {
        if (language?.toLocaleLowerCase() === 'en') {
            return <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5 }}>EN <img src={USFlag} alt="English" className="h-4 w-4 mr-2" /></span>
        } else {
            return <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5 }}>ES <img src={MXFlag} alt="Español" className="h-4 w-4 mr-2" /></span>
        }
    }
    return (
        <div className="relative group">
            <button className="text-white focus:outline-none">{showFlagFromCurrentLanguage(t('lang_code_selected'))}</button>
            <div className="absolute right-0 w-48 bg-white text-gray-800 rounded-md shadow-lg py-2 z-20 hidden group-hover:block dark:bg-gray-700 dark:text-white">
                <button
                    onClick={() => changeLanguage('en')}
                    className="flex items-center px-4 py-2 text-sm w-full text-left hover:bg-gray-200 dark:hover:bg-gray-600"
                >
                    <img src={USFlag} alt="English" className="h-4 w-4 mr-2" />
                    {t('english')}
                </button>
                <button
                    onClick={() => changeLanguage('es')}
                    className="flex items-center px-4 py-2 text-sm w-full text-left hover:bg-gray-200 dark:hover:bg-gray-600"
                >
                    <img src={MXFlag} alt="Español" className="h-4 w-4 mr-2" />
                    {t('spanish')}
                </button>
            </div>
        </div>
    );
};
