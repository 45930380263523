import React from 'react'
import { AuthenticatedLayout } from 'Layouts/AuthenticatedLayout'
import { BusinessList } from 'components/BusinessList/BusinessList'

export const Home = () => {
    return (
        <AuthenticatedLayout>
            <BusinessList />
        </AuthenticatedLayout>
    )
}