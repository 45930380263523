import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay, Locale } from 'date-fns';
import { es, enUS } from 'date-fns/locale';
import enTranslation from './locales/en.json';
import esTranslation from './locales/es.json';

const resources = {
    en: {
        translation: enTranslation,
    },
    es: {
        translation: esTranslation,
    },
};

const locales: { en: Locale; es: Locale } = {
    en: enUS,
    es: es,
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'es',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export const getCalendarLocalizer = () => {
    const locale = i18n.language as 'en' | 'es';

    const localeObject = locales[locale as keyof typeof locales];

    return dateFnsLocalizer({
        format,
        parse,
        startOfWeek: () => startOfWeek(new Date(), { locale: localeObject }),
        getDay,
        locales: {
            [locale]: localeObject,
        }
    });
};

export default i18n;
