import React, { useEffect } from 'react';
import DayNightIcon from 'assets/img/day-and-night.png';
import { RootState, useAppDispatch } from 'app_redux/store';
import { setTheme } from 'app_redux/slices/themeSlice';
import { useSelector } from 'react-redux';

export const ThemeToggle = () => {
    const dispatch = useAppDispatch()
    const isDarkModeActive = useSelector((state: RootState) => state.theme.darkMode);
    const toggleTheme = () => {
        const html = document.documentElement;
        if (html.classList.contains('dark')) {
            html.classList.remove('dark');
            localStorage.setItem('theme', 'light');
            dispatch(setTheme('light'));
        } else {
            html.classList.add('dark');
            localStorage.setItem('theme', 'dark');
            dispatch(setTheme('dark'));
        }
    };

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme') || 'light';
        if (savedTheme === 'dark') {
            document.documentElement.classList.add('dark');
            dispatch(setTheme('dark'));
        } else {
            document.documentElement.classList.remove('dark');
            dispatch(setTheme('light'));
        }
    }, [dispatch]);

    return (
        <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" onChange={toggleTheme} className="sr-only peer" checked={isDarkModeActive} />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-500 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <img src={DayNightIcon} alt="Español" className="h-6 w-6 ml-2" />
        </label>
    );
};
