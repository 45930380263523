import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { login } from 'app_redux/slices/authSlice';
import { RootState, useAppDispatch } from 'app_redux/store';

interface IFormInput {
    identifier: string;
    password: string;
}

export const Login: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<IFormInput>();
    const { t } = useTranslation();


    const dispatch = useAppDispatch();
    const { loading, error } = useSelector((state: RootState) => state.auth);

    const onSubmit: SubmitHandler<IFormInput> = (data) => {
        dispatch(login(data));
    };

    return (
        <div className="min-h-screen bg-gray-100 dark:bg-gray-800 flex flex-col justify-center sm:py-12">
            <div className="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
                <h1 className="font-bold text-center text-2xl mb-5">{t('login')}</h1>
                <div className="bg-white dark:bg-gray-700 shadow w-full rounded-lg divide-y divide-gray-200 dark:divide-gray-600">
                    <div className="px-5 py-7">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <label className="font-semibold text-sm text-gray-600 dark:text-gray-300 pb-1 block">{t('email')}</label>
                            <input
                                {...register('identifier', { required: true })}
                                autoComplete='email'
                                type="text"
                                className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full dark:bg-gray-600 dark:text-gray-100"
                                placeholder={t('email')}
                            />
                            {errors.identifier && <p className="text-red-500 text-xs mt-1">{t('email')} {t('is_required')}</p>}

                            <label className="font-semibold text-sm text-gray-600 dark:text-gray-300 pb-1 block">{t('password')}</label>
                            <input
                                {...register('password', { required: true })}
                                autoComplete="current-password"
                                type="password"
                                className="border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full dark:bg-gray-600 dark:text-gray-100"
                                placeholder={t('password')}
                            />
                            {errors.password && <p className="text-red-500 text-xs mt-1">{t('password')} {t('is_required')}</p>}

                            {error && <p className="text-red-500 text-xs mt-1">{error}</p>}

                            <button
                                type="submit"
                                className="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
                            >
                                <span className="inline-block mr-2">{t('login_button')}</span>
                            </button>
                        </form>
                    </div>
                    <div className="py-5">
                        <div className="grid grid-cols-2 gap-1">
                            <div className="text-center sm:text-left whitespace-nowrap">
                                <button className="transition duration-200 mx-5 px-5 py-4 cursor-pointer font-normal text-sm rounded-lg text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ring-inset dark:text-gray-300 dark:hover:bg-gray-600 dark:focus:bg-gray-700">
                                    <span className="inline-block ml-1">{t('forgot_password')}</span>
                                </button>
                            </div>
                            <div className="text-center sm:text-right whitespace-nowrap">
                                <button className="transition duration-200 mx-5 px-5 py-4 cursor-pointer font-normal text-sm rounded-lg text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ring-inset dark:text-gray-300 dark:hover:bg-gray-600 dark:focus:bg-gray-700">
                                    <span className="inline-block ml-1">{t('signup')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {loading && <div className="text-center mt-4">{t('loading')}</div>}
            </div>
        </div>
    );
};
