import { useTranslation } from 'react-i18next';

export const useTranslationUtils = () => {
  const { t } = useTranslation();

  const getMonthTranslation = (date: Date) => {
    const monthIndex = date.getMonth();
    const monthKey = [
      "month.january", "month.february", "month.march", "month.april", "month.may",
      "month.june", "month.july", "month.august", "month.september", "month.october",
      "month.november", "month.december"
    ][monthIndex];
    return t(monthKey);
  };

  const getDayOfWeekTranslation = (date: Date) => {
    const dayIndex = date.getDay();
    const dayKey = [
      "day_of_week.sunday", "day_of_week.monday", "day_of_week.tuesday",
      "day_of_week.wednesday", "day_of_week.thursday", "day_of_week.friday",
      "day_of_week.saturday"
    ][dayIndex];
    return t(dayKey);
  };

  return { getMonthTranslation, getDayOfWeekTranslation };
};