import { useCallback } from 'react';

const useFormatToMXN = () => {
    const formatToMXN = useCallback((value: number | string) => {
        const numericValue = typeof value === 'string' ? parseFloat(value) : value;
        if (isNaN(numericValue)) return 'Invalid number';

        return new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
        }).format(numericValue);
    }, []);

    return formatToMXN;
};

export default useFormatToMXN;
