import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'app_redux/store';
import { logout } from 'app_redux/slices/authSlice';

interface ProtectedRouteProps {
    children: React.ReactElement;
}

export const ProtectedRoute = React.memo(({ children }: ProtectedRouteProps) => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isAuthenticated) {
            dispatch(logout());
        }
    }, [isAuthenticated, dispatch]);

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    return children;
});

