import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app_redux/store';
import { AuthUserRoutes, IPageRoute, PublicRoutes } from 'utils/Routes';
import AvatarDropdown from './AvatarDropdown';

export const TopBar = () => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    const routes = useMemo(() => {
        return isAuthenticated ? AuthUserRoutes : PublicRoutes;
    }, [isAuthenticated]) as IPageRoute[];

    const { t } = useTranslation();

    return (
        <div className="bg-gray-800 text-white p-4 flex justify-between items-center dark:bg-gray-900">
            <div className="flex items-center">
                {/* <img src="/path-to-logo.png" alt="Logo" className="h-8 w-8 mr-2" /> */}
                <span className="font-semibold text-xl">{t('app_name')}</span>
            </div>
            <div className="flex items-center gap-x-10">
                {routes.map((route) => (
                    <Link key={route.translation_tag} to={route.path} className="text-white mr-4">
                        {t(route.translation_tag)}
                    </Link>
                ))}
                <AvatarDropdown />
            </div>
        </div>
    );
};
