import React, { ReactNode, useEffect } from 'react';
import TopBar from 'components/TopBar';
import Footer from 'components/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'app_redux/store';
import { Navigate } from 'react-router-dom';
import httpApi from 'httpApi';
import { logout } from 'app_redux/slices/authSlice';
import { setTheme } from 'app_redux/slices/themeSlice';

interface AuthenticatedLayoutProps {
    children: ReactNode;
}

export const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({ children }) => {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log('AuthenticatedLayout useEffect', isAuthenticated, dispatch);
        const savedTheme = localStorage.getItem('theme') || 'light';
        const themeClass = savedTheme === 'dark' ? 'dark' : '';
        document.documentElement.className = themeClass;
        dispatch(setTheme(savedTheme));

        const checkAuth = async () => {
            try {
                if (!navigator.onLine) {
                    dispatch(logout());
                    return;
                }
                const response = await httpApi.get('/api/users/me');
                if (!response.data) {
                    dispatch(logout());
                }
            } catch (error) {
                dispatch(logout());
            }
        };

        checkAuth();
    }, [dispatch, isAuthenticated]);

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    return (
        <div className="min-h-screen flex flex-col bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100">
            <TopBar />
            <main className="flex-grow">
                {children}
            </main>
            <Footer />
        </div>
    );
};
