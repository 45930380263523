import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor, store } from 'app_redux/store';
import Home from 'pages/Home';
import LoginPage from 'pages/Login';
import SignupPage from 'pages/Signup';
import NotFoundPage from 'pages/NotFoundPage';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { PublicLayout } from 'Layouts/PublicLayout';
import { PersistGate } from 'redux-persist/integration/react';
import ScheduleAppointment from 'pages/ScheduleAppointmentPage';
import Appointment from 'pages/Appointment';

const App = () => {
  return (
    <Provider store={ store }>
      <PersistGate loading={ null } persistor={ persistor }>
        <Router>
          <Routes>
            <Route path="/login" element={ <PublicLayout><LoginPage /></PublicLayout> } />
            <Route path="/signup" element={ <PublicLayout><SignupPage /></PublicLayout> } />
            <Route path="/schedule-appointment/:businessId" element={ <ProtectedRoute><ScheduleAppointment /></ProtectedRoute> } />
            <Route path="/" element={ <ProtectedRoute><Home /></ProtectedRoute> } />
            <Route path="/appointment" element={ <ProtectedRoute><Appointment /></ProtectedRoute> } />
            <Route path="*" element={ <NotFoundPage /> } />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
